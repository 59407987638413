<template>
  <div class="bg-white">
    <div class="content content-full">
      <div class="row">
        <div
          class="col-lg-6 col-md-6"
          v-for="(item, index) in indexImageList"
          :key="index"
        >
          <div @click="play(item.url)">
            <h4 class="content-heading">{{ item.title }}</h4>
            <a
              class="block block-rounded block-link-pop box-shadow"
              href="javascript:void(0)"
            >
              <img class="img-fluid" :src="item.img" alt="" />
              <div class="block-content">
                <p class="font-size-sm">
                  <span class="text-primary">{{ item.title }}</span>
                </p>
                <p>
                  {{ item.desc }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      indexImageList: [
        {
          name: "lightPlatform",
          title: "物联网轻平台",
          url: "https://oss.cnwscada.com/video/lightPlatform.mp4",
          img: "https://oss.cnwscada.com/video/lightPlatform.png",
          desc: "这是一个基于云屏云盒网页发布，实现的超级链接平台",
        },
        {
          name: "insertMysql",
          title: "云盒子写入Mysql数据库",
          url: "https://oss.cnwscada.com/video/insert_mysql.mp4",
          img: "https://oss.cnwscada.com/video/insert_mysql.png",
          desc: "云盒子通过Python实现向Mysql数据库插入数据",
        },
        {
          name: "voiceAlarm",
          title: "AI语音报警",
          url: "https://oss.cnwscada.com/video/voiceAlarm.mp4",
          img: "https://oss.cnwscada.com/video/voiceAlarm.png",
          desc: "云盒子实现AI语音报警",
        },
        {
          name: "publicWeb",
          title: "纯web发布功能演示",
          url: "https://oss.cnwscada.com/video/publicWeb.mp4",
          img: "https://oss.cnwscada.com/video/publicWeb.png",
          desc: "纯web发布功能演示",
        },
        {
          name: "reportDemo",
          title: "模板报表演示",
          url: "https://oss.cnwscada.com/video/reportDemo.mp4",
          img: "https://oss.cnwscada.com/video/reportDemo.png",
          desc: "模板报表演示",
        },
      ],
    };
  },
  methods: {
    play(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}
.title {
  font-size: 28px;
  line-height: 120%;
  padding-bottom: 1.5rem;
  text-align: center;
  padding-top: 2.5rem;
}
.img-fluid {
  border-radius: 0.2rem;
  padding: 15px;
}
.box-shadow {
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}
</style>
